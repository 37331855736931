import React from "react";
import styled from "styled-components";
import { Container, Row, Col, Nav, Tab } from "react-bootstrap";
import { Link } from "gatsby";
import { Section, Title, Text, Box, Span, List } from "../../components/Core";

const NavStyled = styled(Nav)`
  border-radius: 10px;
  border: 1px solid #eae9f2;
  background-color: #fbfbfe;
  padding-top: 15px;
  padding-bottom: 15px;
  a {
    color: ${({ theme }) => theme.colors.dark} !important;
    &:hover,
    &:active,
    &:visited {
      color: ${({ theme }) => theme.colors.secondary} !important;
    }
  }
`;

const ListStyled = styled(List)`
  font-size: 20px;
  li {
    font-weight: 400;
    &:before {
      min-width: 7px;
      max-width: 7px;
      min-height: 7px;
      max-height: 7px;
    }
  }
`;

const Faq = () => {
  return (
    <>
      <Section>
        <Container>
          <Tab.Container id="left-tabs-example" defaultActiveKey="general">
            <Row>
              <Col md="4" className="mb-5 mb-md-0">
                <NavStyled className="flex-column mr-md-5">
                  <Nav.Link eventKey="general">Alkemi Network</Nav.Link>
                  <Nav.Link eventKey="token">ALK Token</Nav.Link>
                  <Nav.Link eventKey="earn">Alkemi Earn</Nav.Link>
                  <Nav.Link eventKey="mining">Liquidity Mining</Nav.Link>
                  <Nav.Link eventKey="other">Other</Nav.Link>
                </NavStyled>
              </Col>
              <Col md="8">
                <Tab.Content>
                  <Tab.Pane eventKey="general">
                    <Box>
                      <Box mb={4}>
                        <Title variant="card" mb={3} fontSize="24px">
                          What is Alkemi Network?
                        </Title>
                        <Text variant="small">
                          Alkemi is a decentralized liquidity network running on
                          the Ethereum blockchain, offering DeFi access to
                          everyone through a suite of tools and products that
                          serve as onramps to a new financial infrastructure.
                          Alkemi technology utilizes Web3 innovation to
                          facilitate the migration from the legacy financial
                          system to the future of natively-digital capital
                          coordination.
                        </Text>
                      </Box>
                      <Box mb={4}>
                        <Title variant="card" mb={3} fontSize="24px">
                          What problem does Alkemi Network solve?
                        </Title>
                        <Text variant="small">
                          Alkemi Network solves the friction points for CeFi
                          institutions to participate in DeFi, enabling their
                          contribution to a global, decentralized liquidity
                          network that can be accessed and governed by the
                          network's community tokenholders. Alkemi enables the
                          coexistence of both permissioned 'Verified' and
                          permissionless 'Open' liquidity pools linked to
                          protocols governed by the network's native
                          multi-utility token.
                        </Text>
                      </Box>
                      <Box mb={4}>
                        <Title variant="card" mb={3} fontSize="24px">
                          What is the rollout strategy for Alkemi Network?
                        </Title>
                        <Text variant="small">
                          In order to achieve a decentralized liquidity network,
                          all counterparties must be able to interact freely
                          within the financial ecosystem. However, the initially
                          retail-focused innovations of decentralized finance
                          were not designed for institutional participation
                          requirements. The flagship protocol, Alkemi Earn
                          (Earn) is tailored to provide CeFi institutions with a
                          frictionless portal to DeFi, integrating advanced
                          reporting and risk management features within a
                          trusted-counterparty permissioned environment secured
                          by a KYC / AML partner. Earn enables the wall of
                          institutional capital to permeate the market; CeFi
                          institutions can now participate in the DeFi
                          revolution. This constitutes the first chapter of the
                          network rollout strategy.
                        </Text>
                        &nbsp;
                        <Text variant="small">
                          With rising institutional participation, access has
                          been extended to other non-KYC / AML approved users
                          wishing to participate in Alkemi Network via a
                          secondary permissionless pool, 'Open', for the Earn
                          protocol. This open pool was made available at the
                          token generation event for the network's native
                          multi-utility token, ALK. This constitutes the second
                          chapter of the rollout strategy.
                        </Text>
                        &nbsp;
                        <Text variant="small">
                          Alkemi Network is structured as a decentralised
                          community-governed DAO, where ALK token holders have
                          the authority to create and vote on proposals. The
                          network is in the hands of the community, with the
                          role of the Alkemi team as “facilitators”. The Alkemi
                          Network will offer limited voting functionalities at
                          the beginning, but is intended to transition towards a
                          fully decentralised, self-governing DAO in a legally
                          compliant manner, in particular ensuring that the
                          native ALK token is not deemed in any circumstances to
                          be a security or equity-like interest.
                        </Text>
                      </Box>
                      <Box mb={4}>
                        Didn’t find your answer?{" "}
                        <Link to="/contact">
                          <Span color="primary">Contact us here</Span>
                        </Link>
                      </Box>
                    </Box>
                  </Tab.Pane>
                  <Tab.Pane eventKey="token">
                    <Box>
                      <Box mb={4}>
                        <Title variant="card" mb={3} fontSize="24px">
                          Is there a native token for Alkemi Network?
                        </Title>
                        <Text variant="small">
                          The native permissionless multi-utility ALK community
                          token accompanies the formation of the Alkemi
                          Foundation and establishment of the formal DAO
                          structure of the network. The Token Generation Event
                          (TGE) marks a further step towards progressive
                          decentralization.
                        </Text>
                      </Box>
                      <Box mb={4}>
                        <Title variant="card" mb={3} fontSize="24px">
                          What was the ALK token TGE?
                        </Title>
                        <Text variant="small">
                          ALK was initially generated and issued as ERC-20
                          standard compliant multi-utility tokens on the
                          Ethereum blockchain.
                        </Text>
                      </Box>
                      <Box mb={4}>
                        <Title variant="card" mb={3} fontSize="24px">
                          What are the utility features of the ALK token?
                        </Title>
                        <Text variant="small">
                          <Span fontWeight={600}>Governance:</Span> One utility
                          component of the ALK token will be its use in
                          governing the Alkemi Network. Each token will entitle
                          the holder to one vote on all Alkemi Improvement
                          Proposals (AIPs).
                        </Text>
                        &nbsp;
                        <Text variant="small">
                          <Span fontWeight={600}>Staking:</Span> Staking: In
                          addition to bestowing voting rights, ALK tokens will
                          also carry the future utility of unlocking staking
                          rewards within the Alkemi Network. ALK token holders
                          will be able to stake their utility tokens in the
                          Alkemi Vault as collateral to provide an additional
                          security/insurance layer to Alkemi Network protocols
                          and access certain preferential terms, including
                          improved collateralization rates for borrowing.
                        </Text>
                        &nbsp;
                        <Text variant="small">
                          Note: ALK tokens will continue to confer voting rights
                          to holders upon staking.
                        </Text>
                        &nbsp;
                        <Text variant="small">
                          <Span fontWeight={600}>Access:</Span> There will be
                          additional utility functionality features established
                          as the network evolves.
                        </Text>
                      </Box>
                      <Box mb={4}>
                        Didn’t find your answer?{" "}
                        <Link to="/contact">
                          <Span color="primary">Contact us here</Span>
                        </Link>
                      </Box>
                    </Box>
                  </Tab.Pane>
                  <Tab.Pane eventKey="earn">
                    <Box>
                      <Box mb={4}>
                        <Title variant="card" mb={3} fontSize="24px">
                          What is Alkemi Earn?
                        </Title>
                        <Text variant="small">
                          Alkemi Earn is an institution-grade DeFi borrowing and
                          lending protocol featuring permissioned and
                          permissionless liquidity pools.
                        </Text>
                        &nbsp;
                        <Text variant="small">
                          The permissioned pool, 'Verified', is tailored for
                          institutional clients, solving the friction points of
                          capital, connectivity and control and enabling their
                          participation in the emerging on-chain structured
                          financial product yields of Decentralized Finance.
                          Integrating advanced reporting and risk management
                          features with a trusted-counterparty environment
                          secured by the KYC / AML partner, Earn permissioned
                          facilitates CeFi institution participation in the DeFi
                          revolution.
                        </Text>
                        &nbsp;
                        <Text variant="small">
                          Earn's permissionless pool, 'Open', provides open
                          borrowing and lending DeFi access for everyone.
                        </Text>
                      </Box>
                      <Box mb={4}>
                        <Title variant="card" mb={3} fontSize="24px">
                          What is the cost of using Earn?
                        </Title>
                        <Text variant="small">
                          Borrowers pay 0.1% of the borrow amount as an
                          origination fee, which is added to the total borrow
                          amount in its respective currency.
                        </Text>
                        &nbsp;
                        <Text variant="small">
                          Liquidations carry a 10% fee paid to the liquidator.
                          All transactions incur Ethereum blockchain-related gas
                          fees paid by the transactor.
                        </Text>
                        &nbsp;
                        <Text variant="small">
                          It is important to reiterate that a pool simply
                          comprises a set of autonomous Alkemi smart contracts
                          deployed on the relevant blockchain network, operated
                          directly by users calling functions on it (which
                          allows them to interact with other users and/or pool
                          their own selected assets in a peer-to-peer manner).
                          There is no further control by or interaction with the
                          original entity which had deployed the smart contract
                          (which entity solely functions as a provider of
                          technical tools for users, and is not offering any
                          sort of securities product or regulated service).
                        </Text>
                      </Box>
                      <Box mb={4}>
                        <Title variant="card" mb={3} fontSize="24px">
                          Where are my deposited funds stored?
                        </Title>
                        <Text variant="small">
                          Funds are deposited into the Alkemi Earn smart
                          contract. Alkemi Network technology facilitates
                          non-custodial transactions by design; every network
                          participant retains custody and allocates their own
                          funds. The code of the smart contract is public, open
                          source, formally verified and audited by third party
                          auditors. Depositors may withdraw funds from the pool
                          at any time, on-demand. Please note: full withdrawal
                          of funds may be impacted by any depositor borrowing or
                          liquidity mining activities.
                        </Text>
                      </Box>
                      <Box mb={4}>
                        <Title variant="card" mb={3} fontSize="24px">
                          Are there any risks?
                        </Title>
                        <Text variant="small" mb={3}>
                          Whilst Alkemi Network has gone to great lengths to
                          maximize security for participants, smart contract
                          risk (the risk of a bug within the protocol code) and
                          liquidation risk (risk on the collateral liquidation
                          process) are still present., and the Company and
                          Distributor express disclaims any liability in
                          connection with the same. The following risk
                          mitigation procedures have been undertaken:
                        </Text>
                        <ListStyled mb={3}>
                          <li>Internal testing, real-time monitoring.</li>
                          <li>
                            Utilization of security-trialled, community-reviewed
                            code libraries where available, continuously
                            improved and tested by developers worldwide.
                          </li>
                          <li>Alkemi Network utilizes Zeppelin Defender.</li>
                          <li>
                            The smart contract audit by Quantstamp is complete.
                          </li>
                        </ListStyled>
                        <Text variant="small">
                          Additionally, a bug bounty campaign will be unveiled
                          in due course.
                        </Text>
                      </Box>
                      <Box mb={4}>
                        Please see our{" "}
                        <a
                          href="https://app.gitbook.com/@alkemi-network/s/alkemi-network-docs/security-statement"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <Span color="primary">Security Statement</Span>
                        </a>{" "}
                        for more details
                      </Box>
                    </Box>
                  </Tab.Pane>
                  <Tab.Pane eventKey="mining">
                    <Box>
                      <Box mb={4}>
                        <Title variant="card" mb={3} fontSize="24px">
                          What is the liquidity rewards program?
                        </Title>
                        <Text variant="small">
                          Liquidity Mining is available to Alkemi Earn
                          participants depositing and borrowing within the ETH,
                          WBTC, USDC and DAI digital asset markets in the
                          'Verified' pool. It is also available to participants
                          depositing and borrowing within the ETH and USDC
                          markets in the 'Open' pool.
                        </Text>
                      </Box>
                      <Box mb={4}>
                        Please see the{" "}
                        <a
                          href="https://app.gitbook.com/@alkemi-network/s/alkemi-network-docs/liquidity-mining-program"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <Span color="primary">Liquidity Mining Program</Span>
                        </a>{" "}
                        for more details.
                      </Box>
                    </Box>
                  </Tab.Pane>
                  <Tab.Pane eventKey="other">
                    <Box>
                      <Box mb={4}>
                        <Title variant="card" mb={3} fontSize="24px">
                          Will there be bounty programs?
                        </Title>
                        <Text variant="small">
                          As part of the vision to be technologically and
                          financially decentralized, opportunities will be made
                          available for participants to become members of the
                          Alkemi Network community. Initiatives including bug
                          bounties, hackathons and other programs will be
                          established, allocating tokens from the Alkemi
                          Foundation towards developing our community. More
                          details will be provided in due course.
                        </Text>
                      </Box>
                      <Box mb={4}>
                        Didn’t find your answer?{" "}
                        <Link to="/contact">
                          <Span color="primary">Contact us here</Span>
                        </Link>
                      </Box>
                    </Box>
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </Container>
      </Section>
    </>
  );
};
export default Faq;

import React from "react";
import PageTitle from "../sections/common/PageTitle";
import PageWrapper from "../components/PageWrapper";
import Faqs from "../sections/faq/Faq";
import { Helmet } from "react-helmet";

const Questions = () => {
  return (
    <>
      <Helmet>
        <title>FAQs | Alkemi Network</title>
        <meta
          name="description"
          content="Frequently Asked Questions about Alkemi Network and Alkemi Earn and products"
        />
        <meta property="og:title" content="FAQs | Alkemi Network" />
        <meta
          property="og:description"
          content="Frequently Asked Questions about Alkemi Network and Alkemi Earn and products"
        />
        <meta property="og:type" content="website" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:url" content="https://alkemi.network/faqs/" />
        <link rel="canonical" href="https://alkemi.network/faqs/" />
      </Helmet>
      <PageWrapper footerDark>
        <PageTitle title="Alkemi Network FAQs">
          Frequently Asked Questions, please feel free to contact us if you
          can't find an answer.
        </PageTitle>
        <Faqs />
      </PageWrapper>
    </>
  );
};
export default Questions;
